<template>
  <div class="screen">
    <div v-show="signFlag">
      <img class="headImg" src="@/assets/ad/ad.jpg" />
    </div>
    <div v-show="!signFlag">
      <el-card shadow="always">
        <div style="margin-bottom:20px"><span style="font-size:50px;color:#ff0000">选手信息</span></div>
        <div class="onlive_txt">姓名：{{child.childName}}</div>
          <div class="onlive_txt">展演组别：{{child.team}}</div>
          <div class="onlive_txt">展演项目：{{child.workName}}</div>
      </el-card>
    </div>
    <br/>
    <el-button @click="showSign">{{signFlag ? "显示" : "确认"}}</el-button>
  </div>
</template>

<script>
import enrollApi from '../api/enroll.js'

export default {
  data() {
    return {
      id:0,
      signFlag: true,
      child:{childName:'',team:'',workName:''}
    }
  },
  methods: {
    showSign(){
      if(this.signFlag){
        this.signFlag = false
        var that = this
        enrollApi.getLastSignChild(this.placeId).then(res => {
          if(res.data.success){
            if(res.data.data.child != null) {
              that.child = res.data.data.child
            }
          }
        })
      }else{
        this.signFlag = true
      }
    },
  },
  created() {
    if(this.$route.query.place > 0) {
      this.placeId = this.$route.query.place
      // this.getlist.call(this)
    }
  }
}
</script>
<style scoped>
.screen{
  margin: 0 auto;
  padding: 10px;
}
.screenHead{
  margin-bottom: 20px;
  background-image: url(../assets/11.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color:#fff;
}
.headImg{
  width: 100%;
  border-radius: 5px;
  margin-bottom: 5px;
}
.signShow1,.signShow2{
  border-radius:4px;
  border: 1px solid #EBEEF5;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  height: 130px;
}
.signShow2{
  height: calc(100vh - 365px);
}
.signShow{
  clear: both;
  height: calc(100vh - 320px);
}
.block{
  float: left;
  padding-bottom: 20px;
}
.title{
  font-size: 20px;
}
.onlive_txt{
  margin: 20px;
  font-size: 32px;
}
</style>